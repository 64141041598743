import { useQuery } from '@graphcommerce/graphql'
import { StoreConfigDocument } from '@graphcommerce/magento-store'
import { safeJsonLdReplacer } from '@graphcommerce/next-ui/JsonLd/safeJsonLdReplacer'
import Head from 'next/head'

export function OrganizationSchema() {
  const storeConfig = useQuery(StoreConfigDocument).data?.storeConfig
  const org = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    url: storeConfig?.secure_base_url,
    name: storeConfig?.website_name,
    logo: storeConfig?.header_logo_src,
    description: storeConfig?.default_description,
    contactPoint: [
      { '@type': 'ContactPoint', telephone: '088 - 990 86 15', contactType: 'customer service' },
    ],
    address: {
      addressCountry: 'Nederland',
      postalCode: '8243 PC',
      addressRegion: 'Lelystad',
      addressLocality: 'Lelystad',
      streetAddress: 'Schutweg 23',
    },
    sameAs: ['https://www.facebook.com/danl'],
  }

  const website = {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    url: storeConfig?.secure_base_url,
    potentialAction: {
      '@type': 'SearchAction',
      target: `${storeConfig?.secure_base_url}/search/{search_term_string}`,
      'query-input': 'required name=search_term_string',
    },
  }

  return (
    <Head>
      <script
        key='websiteLd'
        type='application/ld+json'
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: JSON.stringify(website, safeJsonLdReplacer) }}
      />
      <script
        key='OrganisationLd'
        type='application/ld+json'
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: JSON.stringify(org, safeJsonLdReplacer) }}
      />
    </Head>
  )
}
