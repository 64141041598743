import { Box } from '@mui/material'
import { ReactNode } from 'react'
import { Logo } from './Logo'

interface MobileStickyBarProps {
  menuFab?: ReactNode
  searchBar?: ReactNode
  cartFab?: ReactNode
}

export function MobileStickyBar({ menuFab, searchBar, cartFab }: MobileStickyBarProps) {
  return (
    <Box
      id='bar'
      sx={(theme) => ({
        display: 'none',
        [theme.breakpoints.down('md')]: {
          display: 'flex',
          position: 'fixed',
          bottom: 0,
          height: '70px',
          width: '100%',
          p: theme.spacings.xs,
          gap: theme.spacings.xs,
          zIndex: 1,
          backgroundColor: 'background.default',
          alignItems: 'center',
        },
      })}
    >
      <Logo
        sx={{
          height: 46,
          m: 0,
          '& .GcLogo-logo': {
            height: 46,
            p: 0,
          },
        }}
      />
      <Box>{menuFab}</Box>
      <Box sx={{ width: '100%' }}>{searchBar}</Box>
      <Box>{cartFab}</Box>
    </Box>
  )
}
