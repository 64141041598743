import { useScrollOffset } from '@graphcommerce/framer-next-pages'
import { Image } from '@graphcommerce/image'
import { iconChevronUp, iconCustomerService, IconSvg } from '@graphcommerce/next-ui'
import { LayoutProvider } from '@graphcommerce/next-ui/Layout/components/LayoutProvider'
import { extendableComponent } from '@graphcommerce/next-ui/Styles'
import { i18n } from '@lingui/core'
import { Box, SxProps, Theme, Container, Fab } from '@mui/material'
import { useScroll, useTransform } from 'framer-motion'
import iconGezondheidsCheck from './gezondheidscheck.svg'

export type LayoutDefaultProps = {
  beforeHeader?: React.ReactNode
  header: React.ReactNode
  footer: React.ReactNode
  cartFab: React.ReactNode
  stickyBar?: React.ReactNode
  searchBar?: React.ReactNode
  children?: React.ReactNode
  noSticky?: boolean
  sx?: SxProps<Theme>
} & OwnerState

type OwnerState = {
  noSticky?: boolean
}
const parts = ['root', 'fabs', 'header', 'children', 'footer'] as const
const { withState } = extendableComponent<OwnerState, 'LayoutDefault', typeof parts>(
  'LayoutDefault',
  parts,
)

export function LayoutDefault(props: LayoutDefaultProps) {
  const {
    children,
    header,
    beforeHeader,
    footer,
    cartFab,
    stickyBar,
    searchBar,
    noSticky,
    sx = [],
  } = props

  const scrollWithOffset = useTransform(
    [useScroll().scrollY, useScrollOffset()],
    ([y, offset]: number[]) => y + offset,
  )

  const classes = withState({ noSticky })

  return (
    <Box
      className={classes.root}
      sx={[
        (theme) => ({
          minHeight: '100vh',
          '@supports (-webkit-touch-callout: none)': {
            minHeight: '-webkit-fill-available',
          },
          display: 'grid',
          gridTemplateRows: `auto auto 1fr auto`,
          gridTemplateColumns: '100%',
          backgroundColor: theme.palette.background.default,
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <LayoutProvider scroll={scrollWithOffset}>
        {beforeHeader}
        <Box
          component='header'
          className={classes.header}
          sx={(theme) => ({
            zIndex: theme.zIndex.appBar - 1,
            '& .Sooqr-Container': {
              p: '100px !important',
              backgroundColor: 'red',
            },
          })}
        >
          <Container
            sx={(theme) => ({
              zIndex: theme.zIndex.appBar - 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: theme.appShell.headerHeightSm,
              pointerEvents: 'none',
              '& > *': {
                pointerEvents: 'all',
              },
              [theme.breakpoints.up('md')]: {
                height: theme.appShell.headerHeightMd,
                p: `0 ${theme.page.horizontal} 0`,
                top: 0,
                display: 'flex',
                justifyContent: 'left',
                width: '100%',
              },
              '&.sticky': {
                [theme.breakpoints.down('md')]: {
                  position: 'sticky',
                  top: 0,
                },
              },
            })}
          >
            {header}
            <Box
              sx={(theme) => ({
                display: 'flex',
                justifyContent: 'space-between',
                zIndex: 2,
                [theme.breakpoints.up('sm')]: {
                  position: 'sticky',
                  marginLeft: '6px',
                },
                [theme.breakpoints.down('md')]: {
                  display: 'none',
                },
              })}
            >
              {cartFab}
            </Box>
          </Container>
          {searchBar}
        </Box>
        {stickyBar}
        <div id='sooqr' className='Sooqr-Container'>
          <div className={classes.children}>{children}</div>
          <div className={classes.footer}>{footer}</div>
          <Box
            sx={{
              position: 'fixed',
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
              bottom: 110,
              right: 0,
              zIndex: 2,
            }}
          >
            <Fab
              size='small'
              aria-label={i18n._(/* i18n */ 'Scroll back to top')}
              color='inherit'
              onClick={() => {
                window.scrollTo({ top: 0, behavior: 'smooth' })
              }}
              sx={(theme) => ({
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                backgroundColor: theme.palette.text.secondary,
                '&:hover, &:focus': {
                  backgroundColor: theme.palette.text.secondary,
                },
              })}
            >
              <IconSvg
                src={iconChevronUp}
                sx={(theme) => ({
                  width: '32px',
                  height: '32px',
                  color: theme.palette.primary.contrastText,
                })}
              />
            </Fab>
            <Fab
              href='/informatie-over/gezondheidscheck'
              size='small'
              aria-label='Gezondheidscheck'
              color='inherit'
              sx={{
                p: '10px',
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                backgroundColor: '#10ae4f',
                '&:hover, &:focus': {
                  backgroundColor: '#10ae4f',
                },
              }}
            >
              <Image
                src={iconGezondheidsCheck}
                unoptimized
                fetchPriority='low'
                loading='eager'
                sx={(theme) => ({
                  color: theme.palette.background.paper,
                })}
              />
            </Fab>
            <Fab
              size='small'
              aria-label={i18n._(/* i18n */ 'Chat with us')}
              color='inherit'
              disabled={!globalThis.zE}
              onClick={() => {
                globalThis.zE?.activate?.({ hideOnClose: true })
              }}
              sx={{
                p: '10px',
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                backgroundColor: 'primary.main',
                '&:hover, &:focus, &:active': {
                  backgroundColor: 'secondary.light',
                },
              }}
            >
              <IconSvg
                src={iconCustomerService}
                sx={(theme) => ({
                  width: '32px',
                  height: '32px',
                  color: theme.palette.primary.contrastText,
                })}
              />
            </Fab>
          </Box>
        </div>
      </LayoutProvider>
    </Box>
  )
}
