import { Link, Box, Container } from '@mui/material'
import { UspsHorizontalList } from '../Usps/UspsHorizontalList'
import { UspsQueryFragment } from '../Usps/UspsQueryFragment.gql'

export type DesktopTopHeaderProps = UspsQueryFragment

export function DesktopTopHeader(props: DesktopTopHeaderProps) {
  const { usps } = props

  return (
    <Box
      sx={(theme) => ({
        display: { xs: 'none', sm: 'flex' },
        background: theme.palette.background.default,
        height: '34px',
        [theme.breakpoints.up('md')]: {
          position: 'sticky',
          top: 0,
          zIndex: theme.zIndex.appBar,
        },
      })}
    >
      <Container
        sx={(theme) => ({
          display: 'flex',
          flex: '1 0 auto',
          gap: theme.spacings.xs,
          alignItems: 'center',
          justifyContent: 'space-between',
        })}
      >
        <UspsHorizontalList usps={usps} />
        <Box sx={(theme) => ({ display: 'flex', gap: theme.spacings.xs })}>
          <Link
            href='/over-da/magazine'
            color='textPrimary'
            sx={{ typography: 'body2' }}
            underline='hover'
          >
            Magazine
          </Link>
          <Link
            href='/over-da/folder'
            color='textPrimary'
            sx={{ typography: 'body2' }}
            underline='hover'
          >
            Folder
          </Link>
          <Link
            href='/klantenservice'
            color='textPrimary'
            sx={{ typography: 'body2' }}
            underline='hover'
          >
            Klantenservice
          </Link>
        </Box>
      </Container>
    </Box>
  )
}
