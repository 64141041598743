import { RichText } from '@graphcommerce/graphcms-ui'
import { Box, Container } from '@mui/material'
import { StoreNoticeFragment } from './StoreNotice.gql'

type StoreNoticeProps = StoreNoticeFragment

export const storeNoticeId = 'store-notice'

export function StoreNotice(props: StoreNoticeProps) {
  const { storeNotice } = props

  return (
    <Box id={storeNoticeId}>
      {storeNotice && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: '5px 0',
            backgroundColor: '#fef8a9',
            '& ::selection': {
              backgroundColor: '#fef8a9',
            },
          }}
        >
          <Container>{storeNotice && <RichText {...storeNotice.content} />}</Container>
        </Box>
      )}
    </Box>
  )
}
