import { iconClose, IconSvg } from '@graphcommerce/next-ui'
import { i18n } from '@lingui/core'
import { IconButton, TextFieldProps } from '@mui/material'
import { AnimatePresence, m, useAnimate } from 'framer-motion'
import { useCallback, useEffect } from 'react'
import { SearchTextField } from './SearchTextField'

type SearchBarProps = TextFieldProps & {
  show: boolean
  onHide: () => void
  searchInputRef: React.RefObject<HTMLInputElement>
}

export function SearchBar(props: SearchBarProps) {
  const { show, onHide, onChange, searchInputRef, ...rest } = props
  const [scope, animate] = useAnimate()

  const openAnimation = useCallback(async () => {
    await animate(scope.current, { opacity: 1, height: 65 })
    await animate('input', { opacity: 1 })
  }, [animate, scope])

  const closeAnimation = useCallback(async () => {
    await animate('input', { opacity: 0 })
    await animate(scope.current, { opacity: 0, height: 0 })
  }, [animate, scope])

  useEffect(() => {
    if (show) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      openAnimation()
    } else {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      closeAnimation()
    }
  }, [animate, show, openAnimation, closeAnimation])

  type EventType = EventTarget & (HTMLTextAreaElement | HTMLInputElement)

  const handleClear = () => {
    const event = new Event('input', { bubbles: true })
    if (searchInputRef.current) {
      searchInputRef.current.value = ''
      // This is a dangerously dispatched event with incorrect values, this might cause bugs
      onChange?.({
        ...event,
        target: event.target as EventType,
        nativeEvent: event,
        isDefaultPrevented: () => false,
        isPropagationStopped: () => false,
        persist: () => false,
        currentTarget: event.currentTarget as EventType,
      })
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      closeAnimation()
      onHide()
    }
  }

  return (
    <AnimatePresence>
      <m.div
        ref={scope}
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          height: 0,
          opacity: 0,
        }}
      >
        <SearchTextField
          inputRef={searchInputRef}
          id='search2'
          variant='outlined'
          fullWidth
          sx={{ ml: 2 }}
          inputProps={{
            placeholder: i18n._(/* i18n */ 'Search for...'),
          }}
          onChange={onChange}
          size='small'
          {...rest}
        />
        <IconButton sx={{ mr: 1 }} onClick={handleClear}>
          <IconSvg src={iconClose} />
        </IconButton>
      </m.div>
    </AnimatePresence>
  )
}
