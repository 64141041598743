import { AddProductsToCartForm } from '@graphcommerce/magento-product'
import { GlobalStyles, Theme, useMediaQuery } from '@mui/material'
import Script from 'next/script'
import { CSSProperties, useState } from 'react'
import { SooqrAddToCartFunctions, SooqrScriptProps } from './SooqrAddToCart'

export function SooqrScript(props: SooqrScriptProps) {
  const [ready, setReady] = useState(false)
  const id = import.meta.graphCommerce.sooqrAccount
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  if (process.env.NODE_ENV !== 'production' && !id)
    console.warn('[@graphcommerce/sooqr]: NEXT_PUBLIC_SOOQR_ACCOUNT not found')

  if (!id) return null

  const mobileStyles = {
    '.sooqrSearchContainer': {
      width: '100% !important',
      top: '0 !important',
    },

    '.sooqrSearchContainer .sooqrSearchFilters': {
      position: 'sticky !important' as 'sticky',
      top: 0,
    },
  }

  const desktopStyles = {
    '.sooqrSearchContainer': {
      maxWidth: 'lg',
      width: '100% !important',
      top: '0 !important',
      marginTop: '135px !important',
      boxSizing: 'border-box' as CSSProperties['boxSizing'],
    },

    '.sooqrSearchContainer .sooqrSearchFilters': {
      position: 'sticky !important' as 'sticky',
      top: '0',
    },
  }

  const styles = isMobile ? mobileStyles : desktopStyles

  return (
    <>
      <GlobalStyles styles={styles} />

      <Script
        src={`https://static.spotlersearch.com/custom/${id}/snippet.js`}
        strategy='lazyOnload'
        onLoad={() => setReady(true)}
      />

      <AddProductsToCartForm>
        {ready && <SooqrAddToCartFunctions {...props} />}
      </AddProductsToCartForm>
    </>
  )
}
