import { UseFormSetValue, useFormState } from '@graphcommerce/ecommerce-ui'
import { ElementNode } from '@graphcommerce/graphcms-ui/components/RichText/types'
import { CartItemInput, Exact } from '@graphcommerce/graphql-mesh'
import { useFormAddProductsToCart } from '@graphcommerce/magento-product'
import { CircularProgress } from '@mui/material'
import {
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  BaseSyntheticEvent,
  useCallback,
} from 'react'
import ReactDOM from 'react-dom'
import { AddToCartDialog } from '../Dialogs/AddToCartDialog'

type SooqrEvent = CustomEvent<
  { productId?: string | number; sku?: string | number; isRvg: 0 | 1 } | undefined
>

export type SooqrScriptProps = {
  infoForm?: {
    scriptId: string
  } | null
  callADruggist?: {
    content: {
      raw: ElementNode
    }
  } | null
}

type FunctionProps = {
  e: SooqrEvent
  skus: {
    sku: string | number
    active: boolean
    isRvg: 0 | 1
  }[]
  setSkus: Dispatch<
    SetStateAction<
      {
        sku: string | number
        active: boolean
        isRvg: 0 | 1
      }[]
    >
  >
  setValue: UseFormSetValue<
    Exact<{
      cartId: string
      cartItems: CartItemInput[]
    }>
  >
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  submit: (e?: BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>
  plusElement: Element | null | undefined
  circularProgress: JSX.Element
  setPlusElement: Dispatch<SetStateAction<Element | null | undefined>>
  setOpen: Dispatch<SetStateAction<boolean>>
}

/**
 * - Check if sku exists
 * - Add sku to skus list, for loading state
 * - Set value in form
 * - Submit form
 * - If isRvg -- Open dialog -- Put sku in list
 */
const onClickedAddToCart = (
  props: Pick<FunctionProps, 'e' | 'skus' | 'setSkus' | 'setValue' | 'submit' | 'setOpen'>,
) => {
  const { e, skus, setSkus, setValue, submit, setOpen } = props
  if (!e.detail?.sku) return undefined

  if (e.detail.isRvg === 0) {
    setSkus([
      { ...e.detail, sku: e.detail.sku ?? '', active: true },
      ...(skus?.map((s) => ({ ...s, active: false })) ?? []),
    ])

    setValue(`cartItems.0.sku`, String(e.detail.sku))
    return submit()
  }
  setOpen(true)
  setSkus([
    { ...e.detail, sku: e.detail.sku ?? '', active: true },
    ...(skus?.map((s) => ({ ...s, active: false })) ?? []),
  ])

  return () => new Promise(() => {})
}

/**
 * - Check skus list for active skus
 * - Populate plus element state
 * - Replace plus element with loading indicator for active items
 */
const whileLoadingAddToCart = (
  props: Pick<FunctionProps, 'skus' | 'setPlusElement' | 'plusElement' | 'circularProgress'>,
) => {
  const { skus, setPlusElement, plusElement, circularProgress } = props
  const activeItem = skus?.find((s) => s?.active)
  const lastActiveSku = activeItem?.sku
  const productElement = document.querySelector(`[data-sku="${lastActiveSku}"]`)
  const elementIcon = productElement?.getElementsByClassName('IconSvg-root').item(0)

  if (elementIcon) setPlusElement(elementIcon)

  if (plusElement?.parentElement && lastActiveSku)
    ReactDOM.render(circularProgress, plusElement.parentElement)
}

/**
 * - Check skus list for active items
 * - Replace loading indicator with plus element
 * - Change active state to inactive
 */
const afterCallAddToCart = (props: Pick<FunctionProps, 'skus' | 'plusElement' | 'setSkus'>) => {
  const { skus, plusElement, setSkus } = props
  // const activeItems = skus?.filter((s) => s?.active)
  skus.forEach((item) => {
    const productElement = document.querySelector(`[data-sku="${item.sku}"]`)
    const elementLoadingIndicator = productElement
      ?.getElementsByClassName('MuiCircularProgress-root')
      .item(0)
    if (plusElement) elementLoadingIndicator?.replaceWith(plusElement)
    setSkus([])
  })
}

export function SooqrAddToCartFunctions(props: SooqrScriptProps) {
  const [skus, setSkus] = useState<FunctionProps['skus'][0][]>([])
  const [plusElement, setPlusElement] = useState<Element | null>()
  const [open, setOpen] = useState(false)
  const { setValue, handleSubmit, control } = useFormAddProductsToCart()
  const { isSubmitting } = useFormState({ control })

  const submit = handleSubmit(() => {})

  const triggerEvent = useCallback(
    async (e: SooqrEvent) => {
      await onClickedAddToCart({ e, skus, setSkus, setValue, submit, setOpen })
    },
    [setValue, skus, submit],
  )

  // Triggers event while loading add to cart query
  useEffect(() => {
    whileLoadingAddToCart({
      skus,
      plusElement,
      setPlusElement,
      circularProgress: <CircularProgress size={20} color='inherit' />,
    })
  }, [plusElement, skus])

  // Should only trigger after the submit
  useEffect(() => {
    if (!isSubmitting) {
      afterCallAddToCart({ skus, plusElement, setSkus })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting])

  // Initial event registration
  useEffect(() => {
    const sooqr = globalThis.document.getElementById('sooqr')
    // @ts-expect-error type error for custom event
    sooqr?.addEventListener('clickedAddToCart', triggerEvent)
    // @ts-expect-error type error for custom event
    return () => sooqr?.removeEventListener('clickedAddToCart', triggerEvent)
  }, [isSubmitting, setValue, submit, triggerEvent])

  return (
    <AddToCartDialog
      open={open}
      {...props}
      sku={String(skus[0]?.sku)}
      onClose={() => setOpen(false)}
      onSubmit={() => setOpen(false)}
    />
  )
}
