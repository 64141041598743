import { Scroller, ScrollerProvider } from '@graphcommerce/framer-scroller'
import { RichText, Asset } from '@graphcommerce/graphcms-ui'
import { Box } from '@mui/material'
import { UspsQueryFragment } from './UspsQueryFragment.gql'

export type ProductUspsProps = UspsQueryFragment

export function UspsHorizontalList(props: ProductUspsProps) {
  const { usps } = props

  if (!usps?.uspsMultiple) return null

  return (
    <ScrollerProvider scrollSnapAlign='none'>
      <Scroller
        hideScrollbar
        sx={(theme) => ({
          gap: theme.spacings.xs,
          '& img': { width: 'auto !important', minHeight: '12px', mr: '4px' },
        })}
      >
        {usps?.uspsMultiple.map((usp) => {
          if (!usp.description && !usp.asset) return null
          return (
            <Box
              key={usp.title}
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'nowrap',
                whiteSpace: 'nowrap',
                color: 'text.primary',
                gap: '2px',
              }}
            >
              {usp.asset ? (
                <Box sx={{ flexShrink: 0 }}>
                  <Asset
                    asset={usp.asset}
                    layout='fill'
                    sizes={{ 0: '15px' }}
                    sx={{
                      color: 'black',
                      maxHeight: 10,
                      marginRight: '5px',
                      objectFit: 'contain',
                    }}
                  />
                </Box>
              ) : (
                ''
              )}
              {usp.description ? (
                <RichText
                  raw={usp.description?.raw}
                  sxRenderer={{
                    paragraph: {
                      flex: '0 0 auto',
                      typography: 'body2',
                      color: 'text.secondary',
                      fontWeight: 500,
                    },
                    link: {
                      typography: 'body2',
                      color: 'text.secondary',
                      textDecoration: 'underline',
                    },
                  }}
                />
              ) : (
                ''
              )}
            </Box>
          )
        })}
      </Scroller>
    </ScrollerProvider>
  )
}
