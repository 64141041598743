import { IconSvg, iconSearch } from '@graphcommerce/next-ui'
import TextField, { TextFieldProps } from '@mui/material/TextField'

type SearchTextFieldProps = TextFieldProps

export function SearchTextField(props: SearchTextFieldProps) {
  const { sx, ...rest } = props
  return (
    <TextField
      {...rest}
      InputProps={{
        endAdornment: (
          <IconSvg
            src={iconSearch}
            size='large'
            sx={{
              color: 'text.secondary',
            }}
          />
        ),
      }}
      sx={[
        {
          '& .MuiInputBase-root': {
            borderRadius: '50px',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'text.primary',
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    />
  )
}
